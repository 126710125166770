import { IEventTelemetry } from '@microsoft/applicationinsights-web';
import { BUILD_TS, GIT_HASH } from 'constants/constants';
import { AppInsights } from 'init';
import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';
import keys from 'lodash/keys';
import { InputUtils } from 'utils/inputUtils';
import { ClaimEvent } from 'xstate/claimEvent';
import { DepositEvent } from 'xstate/depositEvent';
import { VaultDepositEvent } from 'xstate/vaultDepositEvent';
import { FarmEvent } from 'xstate/farmEvent';
import { MintEvent } from 'xstate/mintEvent';
import { RedeemEvent } from 'xstate/redeemEvent';
import { StakeEvent } from 'xstate/stakeEvent';
import { UnstakeEvent } from 'xstate/unstakeEvent';
import { WithdrawEvent } from 'xstate/withdrawEvent';

export namespace IchiInsights {
  export enum PageView {
    MINT = 'Mint',
    FARM = 'Farm',
  }

  export namespace Events {
    export enum Wallet {
      CLEAR_TRANSACTION = 'ClearTransaction',
    }
  }

  export type CombinedEvents = Events.Wallet
  | MintEvent
  | RedeemEvent
  | StakeEvent
  | UnstakeEvent
  | ClaimEvent
  | FarmEvent
  | DepositEvent
  | VaultDepositEvent
  | WithdrawEvent;

  export function trackEvent(args: { event: CombinedEvents; properties?: Record<string, string> }) {
    try {
      if (AppInsights) {
        const sanitizedProperties = {};
        forEach(keys(args.properties), (key) => {
          const value = args.properties ? args.properties[key] : null;
          sanitizedProperties[key] = isArray(value) ? value.join('\n') : value;
        });
        const event: IEventTelemetry = {
          name: args.event,
          properties: sanitizedProperties
        };
        AppInsights.trackEvent(event, sanitizedProperties);
      }
    } catch (e) {
      if (AppInsights) {
        console.error(`Insights: Could not track event: ${e.message}`);
      }
    }
  }

  export function trackPageView(pageView: PageView) {
    try {
      if (AppInsights) {
        AppInsights.trackPageView({
          name: pageView,
        });
      }
    } catch (e) {
      if (AppInsights) {
        console.error(`Insights: Could not track page view: ${e.message}`);
      }
    }
  }

  export type TrackException = {
    error: Error;
    message: string;
    handledAt: string;
    txId?: string;
    extra?: Object;
  };
  export function trackException(args: TrackException) {
    try {
      if (AppInsights) {
        args.handledAt = InputUtils.isTruthy(args.handledAt) ? args.handledAt : 'unhandled';
        AppInsights.trackException({
          exception: args.error,
          properties: {
            ErrorMessage: args.message,
            Extra: InputUtils.isTruthy(args.extra) ? JSON.stringify(args.extra, null, ' ') : null,
            handledAt: args.handledAt,
            tx: args.txId
          }
        });
      }
    } catch (e) {
      if (AppInsights) {
        console.error(`Insights: Could not track exception: ${e.message}`);
      }
    }
  }

}
