import styled from 'styled-components';

export const StyledTopbarContainer = styled.div`
  position: relative;
  background: #ffffff;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 0 auto;
}
`;

export const MobileContainer = styled.span`
  @media screen and (max-width: 800px) {
    display: none;
    visibility: hidden;
  }
`;
