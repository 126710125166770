import { sidebarItems } from 'constants/constants';
import React from 'react';
import Navbar from '../common/components/Navbar/Navbar';

type Props = {
  isDisplayed: boolean;
  toggleSidebar: Function;
};

const NavbarContainer: React.FC<Props> = (props) => {
  return (
    <Navbar
      items={sidebarItems}
      isDisplayed={props.isDisplayed}
      toggleSidebar={props.toggleSidebar}
    />
  );
};

export default NavbarContainer;
