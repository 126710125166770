import Image from 'common/components/Image';
import { darkTheme } from 'common/theme/dark/darkTheme';
import { lightTheme } from 'common/theme/light/lightTheme';
import React from 'react';
import useDarkMode from 'use-dark-mode';
import { StyledThemeIcon, StyledThemeSwitcher } from './themeSwitcher.style';

export type ThemeSwitcherProps = {
  onClick?: Function;
};

const ThemeSwitcher: React.FC<ThemeSwitcherProps> = (props) => {
  const darkMode = useDarkMode();

  const onClick = (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    if (props.onClick) {
      props.onClick();
    }
  };

  const theme = darkMode.value ? darkTheme : lightTheme;
  return (
    <StyledThemeSwitcher>
      <StyledThemeIcon>
        <Image
          className="icon-theme-switcher"
          alt="Toggle theme"
          onClick={onClick}
        />
        <Image
          className="icon-theme-switcher-mobile"
          alt="Toggle theme"
          onClick={onClick}
        />
      </StyledThemeIcon>
    </StyledThemeSwitcher>
  );
};

export default ThemeSwitcher;
