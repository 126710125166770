import cx from 'classnames';
import React from 'react';
import { animated, useSpring } from 'react-spring';

type Props = {
  isLoading: boolean;
  className?: string;
  // These default to 0, but if using the spinner with no children,
  // the bounding rect wont' have anything to use, so need some
  // default w/h in this scenario
  width?: number;
  height?: number;
};

const Spinner: React.FC<Props> = (props) => {
  /* Capture the dimensions of the button before the loading happens
  so it doesn’t change size.
  These hooks can be put in a seprate file. */
  const [width, setWidth] = React.useState(props.width || 0);
  const [height, setHeight] = React.useState(props.height || 0);
  const ref = React.useRef<HTMLSpanElement>(null);

  React.useEffect(() => {
    if (ref.current && ref.current.getBoundingClientRect().width) {
      setWidth(ref.current.getBoundingClientRect().width);
    }
    if (ref.current && ref.current.getBoundingClientRect().height) {
      setHeight(ref.current.getBoundingClientRect().height);
    }
  }, [props.children]);

  // Hooks used to fade in/out the loader or the button contents
  const fadeOutProps = useSpring({ opacity: props.isLoading ? 1 : 0 });
  const fadeInProps = useSpring({ opacity: props.isLoading ? 0 : 1 });

  return (
    <span
      className={cx('loadable-default', 'primary-button-color', props.className)}
      ref={ref}
      style={
        props.isLoading
          ? {
            width: `${width}px`,
            height: `${height}px`,
          }
          : {}
      }
    >
      {props.isLoading ? (
        <animated.div style={fadeOutProps}>
          <div className="loader" />
        </animated.div>
      ) : (
        <animated.div style={fadeInProps}>
          {props.children}
        </animated.div>
      )}
    </span>
  );
};

export default Spinner;
