import { ApolloProvider } from '@apollo/client';
import { ResetCSS } from 'common/assets/css/style';
import { StyledContainer, StyledFlexColumn } from 'common/styles/common.styles';
import { darkTheme } from 'common/theme/dark/darkTheme';
import { lightTheme } from 'common/theme/light/lightTheme';
import { GlobalStyle, StyledContent } from 'containers/app.style';
import TopbarContainer from 'containers/TopbarContainer';
import { apolloClient } from 'graphql/client';
import React, { useEffect, useState } from 'react';
import { usePrevious } from 'react-use';
import { ThemeProvider } from 'styled-components';
import useDarkMode from 'use-dark-mode';
import { ScreenUtils } from 'utils/screenUtils';
import SEO from './common/components/seo';

const Index: React.FC = (props) => {
  const darkMode = useDarkMode();
  const breakpoint = ScreenUtils.useBreakpoint();
  const prevBreakpoint = usePrevious(breakpoint);
  const [isSidebarDisplayed, setIsSidebarDisplayed] = React.useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);  
  const [password, setPassword] = useState('');

  const correctPassword = 'ICHI2024'; // Hardcoded password

  // This effect runs only on the client (after initial render)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const savedAuthStatus = sessionStorage.getItem('isAuthorized');
      if (savedAuthStatus === 'true') {
        setIsAuthorized(true);
      }
    }
  }, []);

  const handleLogin = () => {
    if (password === correctPassword) {
      setIsAuthorized(true);
      sessionStorage.setItem('isAuthorized', 'true'); // Store the authorized status in localStorage
    } else {
      alert('Incorrect password');
    }
  };
  // on mount if the screen is S-M hide the sidebar, this can't be done in SSR
  // TODO: Honestly we should use an already made sidebar lib for this, the below approach is not elegant
  useEffect(() => {
    if ([ScreenUtils.BreakpointName.M, ScreenUtils.BreakpointName.S].includes(breakpoint)) {
      setIsSidebarDisplayed(false);
    }
  }, []);

  useEffect(() => {
    if ([ScreenUtils.BreakpointName.XL, ScreenUtils.BreakpointName.L].includes(breakpoint) && !isSidebarDisplayed) {
      setIsSidebarDisplayed(true);
    }

    // If we were previously on a large screen and going smaller, set the sidebar display to false
    if (
      (ScreenUtils.BreakpointName.XL, ScreenUtils.BreakpointName.L).includes(prevBreakpoint as ScreenUtils.BreakpointName) &&
      ![ScreenUtils.BreakpointName.XL, ScreenUtils.BreakpointName.L].includes(breakpoint)
    ) {
      setIsSidebarDisplayed(false);
    }
  }, [breakpoint]);

  const toggleSidebar = () => {
    setIsSidebarDisplayed(!isSidebarDisplayed);
  };

  const theme = darkMode.value ? darkTheme : lightTheme;

  // Check if the user is not authorized, show password prompt
  if (!isAuthorized) {
    return (
      <ThemeProvider theme={theme}>
        <SEO title="ICHI" />
        <ResetCSS />
        <GlobalStyle />
        <StyledFlexColumn className="p-10" alignItems='center' gap='10px'>
          <b>Enter Password to Access the Site</b>
          <div>
            <input
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button onClick={handleLogin}>Submit</button>
          </div>
        </StyledFlexColumn>
      </ThemeProvider>
    );
  }

  // If authorized, render the actual content
  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={apolloClient}>
        <SEO title="ICHI" />
        <ResetCSS />
        <GlobalStyle />
        <StyledContainer>
          {/* <SidebarContainer isDisplayed={isSidebarDisplayed} toggleSidebar={toggleSidebar} /> */}
          <StyledFlexColumn width="100%">
            <TopbarContainer
              toggleTheme={darkMode.toggle}
              toggleSidebar={toggleSidebar}
              isSidebarDisplayed={isSidebarDisplayed}
            />
            <StyledContent className="styled-content">{props.children}</StyledContent>
          </StyledFlexColumn>
        </StyledContainer>
      </ApolloProvider>
    </ThemeProvider>
  );
};

export default Index;
