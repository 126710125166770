import { Colors } from 'common/models/theme';

const lightColors: Colors = {
  transparent: 'transparent', // 0
  primaryTextColor: '#000000',
  warningTextColor: '#de7921',
  spinnerColor: '#0676FF',
  black: '#000000', // 1
  white: '#ffffff', // 2
  headingColor: '#00387C',
  textColor: '#43414e', // 3
  subTextColor: '#666666',
  sidebarTextColor: '#666666',
  sidebarTextSelectedColor: '#333333',
  sidebarItemSelectedColor: '#0676FF',
  labelColor: '#767676', // 4
  inactiveField: '#f2f2f2', // 5
  inactiveButton: '#b7dbdd', // 6
  inactiveIcon: '#EBEBEB', // 7
  primary: '#00BD7B', // 8
  primaryDisabled: '#CCF2E5', // 8
  primaryHover: '#2aa275', // 9
  secondary: '#edcd37', // 10
  secondaryHover: '#edcd37', // 11
  yellow: '#fdb32a', // 12
  yellowHover: '#F29E02', // 13
  // Wallet
  walletButtonBgColor: '#CCF2E5',
  // General
  primaryButtonBgColor: '#00BD7B',
  primaryButtonDisabledBgColor: '#999999',
  primaryButtonColor: '#ffffff',
  secondaryButtonBgColor: '#e8f8f3',
  footerBgColor: '#CCF2E5',
  hrBgColor: '#DBDBDB',
  // borderColor: '#1b1e25', //14
  primaryBoxShadow: '0px 8px 20px -6px rgba(42, 162, 117, 0.57)',
  secondaryBoxShadow: '0px 8px 20px -6px rgba(237, 205, 55, 0.5)',
  sidebarBackgroundColor: '#EDF5FF',
  contentBgColor: '#ffffff',
  contentInnerBgColor: '#EDF5FF',
  contentInnerInnerBgColor: '#EDF5FF',
  annoncementBgColor: '#EDF5FF',
  backgroundColor: '#EDF5FF',
  borderColor: '#C1DCFE',
  hamburgerIcon: '#046FF3',
  // Tabs
  activeTabColor: '#000000',
  inactiveTabColor: '#999999',
  btnViewAllColor: '#0676ff',
  // Treasury
  treasuryPositionBorder: '1px solid #CCCCCC',
  treasuryPositionBgColor: '#ffffff',
  treasuryPositionNameColor: '#666666',
  treasuryTableSubTextTwoColor: '#00BD7B',
  // Farm
  farmDashesColor: '#999999',
  noRewardsButtonColor: '#EDF5FF',
  // General Input
  inputBorder: '1px solid #DBDBDB',
  inputBorderColor: '#DBDBDB',
  inputLabelColor: '#00387C',
  inputBgColor: '#ffffff',
  inputPlaceholderColor: '#666666',
  // General Table
  tableBorderBottom: '1.1px solid #DBDBDB',
  // Ichi Balance
  ichiBalanceButtonBgColor: '#0676FF',
  ichiBalanceButtonBgImage: 'linear-gradient(to right, #0675fc , #0359c2);',
  ichiBalanceBgColor: '#ffffff',
  ichiBalanceHeaderBorderBottom: '1px solid #DBDBDB',
  ichiBalanceHeaderTextColor: '#333333',
  ichiBalancePrimaryTextColor: '#ffffff',
  // Modal
  modalBgColor: '#ffffff',
  // redeem
  innerBoxBgColor: '#ffffff',
  // select oneToken
  selectStablecoinBgColor: '#EDF5FF',
  // Wallet
  walletPopperHeaderBorderBottom: '1px solid #DBDBDB',
  walletPopperHeaderTextColor: '#333333',
  walletPopperBgColor: '#DBDBDB',
  walletPopperTextColor: '#DBDBDB',
};

export default lightColors;
