import React from 'react';

type Props = {
  onClick?: Function;
};

export const HamburgerIcon: React.FC<Props> = (props) => {
  const onClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <svg viewBox="0 0 34 34" width="34" height="34" className="styled-hamburger" onClick={onClick}>
      <rect x="7" y="8" width="20" height="2" />
      <rect x="7" y="16" width="20" height="2" />
      <rect x="7" y="24" width="20" height="2" />
    </svg>
  );
};
