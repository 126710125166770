import React from 'react';
import { StyledFlexRow } from 'common/styles/common.styles';
import { StyledTopbarContainer } from './topbarContainer.style';
import NavbarContainer from './NavbarContainer';
import PrefbarContainer from './PrefbarContainer';
import Button from 'common/components/Button';

type Props = {
  toggleTheme: Function;
  toggleSidebar: Function;
  isSidebarDisplayed: boolean;
};

const TopbarContainer: React.FC<Props> = (props) => {
  const handleLogout = () => {
    if (typeof window !== 'undefined') {
      sessionStorage.removeItem('isAuthorized'); // Clear the authorization status
      window.location.reload(); // Reload the page to trigger the login check
    }
  };

  return (
    <StyledTopbarContainer className="styled-topbar">
      <StyledFlexRow>
        <NavbarContainer
          isDisplayed={props.isSidebarDisplayed}
          toggleSidebar={props.toggleSidebar}
        />
        <StyledFlexRow>
          <Button onClick={handleLogout} title='Logout' style={{background: 'none', color: '#888888'}}></Button>
          <PrefbarContainer
            toggleTheme={props.toggleTheme}
            toggleSidebar={props.toggleSidebar}
          />
        </StyledFlexRow>
      </StyledFlexRow>
    </StyledTopbarContainer>
  );
};

export default TopbarContainer;
