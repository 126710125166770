import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';
import { ScreenUtils } from 'utils/screenUtils';

type StyledFixedSidebarArgs = {
  isDisplayed: boolean;
};

export const SidebarWrapper = styled.div`
  /* width: 270px;
  flex: 0 0 270px;
  height: 100%; */ /*Full-height: remove this if you want "auto" height */
  margin-right: auto;
  
  @media screen and (max-width: 970px) {
    background-color: rgba(10, 17, 19, 0.90);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9999998;
    top: 0px;
    margin-right: 20px;
  }
`;

export const StyledFixedSidebar = styled.div<StyledFixedSidebarArgs>`
  /* Horizontal layout on big screens */
  display: flex;
  flex-direction: row;
  align-items: center;

  /* Vertical layout on smaller screens */
  @media screen and (max-width: 970px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 270px;
    height: 100%;
  }

  @media screen and (max-height: 450px) {
      padding-top: 15px;
      & a {
          font-size: 18px;
      }
  }

  /* @media screen and (max-width: ${ScreenUtils.breakpointMapping[ScreenUtils.BreakpointName.M]}) {
    display: none;
    display: ${(props) => (props.isDisplayed ? 'block' : 'inherit')};
  }
  @media screen and (max-width: ${ScreenUtils.breakpointMapping[ScreenUtils.BreakpointName.S]}) {
    display: none;
  } */
`;

export const StyledSidebarContents = styled.div`
  margin-left: 40px;
  display: flex;
  flex-direction: row;
  
  @media (max-width: 1200px) and (min-width: 971px) {
    margin-left: 10px;
  }

  @media screen and (max-width: 970px) {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-left: 30px;
  }

  & button {
    @media screen and (min-width: 970px) {
      display: none;
    }
  
  }

`;

export const StyledTopItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  @media screen and (max-width: 970px) {
    display: flex;
    flex-direction: column;
    align-items: left;
  }

`;

type StyledTopItemProps = {
  active: boolean;
};
export const StyledTopItem = styled.div<StyledTopItemProps>`
  height: 34px;
  display: inline-flex;
  margin-top: 7px;
  align-items: center;
  padding: 6px 15px 6px 15px;
  /* Unclear what the issue with the single line is, but it won't render in the browser, so must split it up */
  border-bottom-width: ${(props) => (props.active ? '2px' : 'none')};
  border-bottom-style: ${(props) => (props.active ? 'solid' : 'none')};
  border-bottom-color: ${(props) => (props.active ? themeGet('colors.sidebarItemSelectedColor') : 'none')};

  & img {
    display: flex;
    align-items: center;
    margin-left: 17px;
    height: 20px;
    width: 24px;
  }

  & a {
    display: flex;
    align-items: center;
    height: 19px;
    font-size: 16px;
    letter-spacing: 0.02px;
    line-height: 19px;
  }

  @media (max-width: 1200px) and (min-width: 971px) {
    font-size: 14px !important;
    letter-spacing: -1px;
    padding: 6px 12px;
  }


`;

export const StyledBottomItems = styled.div`
  margin-top: 120px;
  padding-top: 20px;
  bottom: 10px;
  cursor: pointer;

  & a {
    display: flex;
    align-items: center;
    padding: 6px 8px 6px 16px;
    height: 19px;
    font-size: 16px;
    letter-spacing: 0.02px;
    line-height: 19px;
    margin-bottom: 12px;
  }
`;

type StyledSmiItemsProps = {
  showSubMenu: boolean;
};
export const StyledSmiItems = styled.div<StyledSmiItemsProps>`
  visibility: ${(props) => (props.showSubMenu ? 'visible' : 'hidden')};
  height: ${(props) => (props.showSubMenu ? 'auto' : '0')};
  padding-left: 30px;
  border: none;

  & a {
    display: flex;
    align-items: center;
    padding: 6px 8px 6px 16px;
    height: 19px;
    font-size: 16px;
    letter-spacing: 0.02px;
    line-height: 19px;
    margin-bottom: 12px;
  }
`;

export const StyledClose = styled.div`
  position: absolute;
  top: 20px;
  left: 290px;

  // If >= L hide the close
  @media screen and (min-width: 970px) {
    display: none;
  }
`;
