import { HamburgerIcon } from 'common/components/HamburgerIcon/HamburgerIcon';
import IchiLogoMobile from 'common/components/IchiLogoMobile/IchiLogoMobile';
// import Preferences from 'common/components/Preferences/Preferences';
import ThemeSwitcher from 'common/components/ThemeSwitcher/ThemeSwitcher';
import { StyledHamburger } from 'common/components/Prefbar/prefbar.style';
import React from 'react';
import Prefbar from '../common/components/Prefbar/Prefbar';

type Props = {
  toggleTheme: Function;
  toggleSidebar: Function;
};

const PrefbarContainer: React.FC<Props> = (props) => {
  return (
    <Prefbar>
      <StyledHamburger>
        <HamburgerIcon onClick={props.toggleSidebar} />
      </StyledHamburger>
      <IchiLogoMobile />
      <ThemeSwitcher onClick={props.toggleTheme} />
    </Prefbar>
  );
};

export default PrefbarContainer;
