import styled from 'styled-components';

export const StyledPrefbar = styled.div`
  height: 101px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 1440px;

  // Sidebar will disappear at 970px
  @media screen and (max-width: 970px) {
    margin-right: 20px;
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;
    height: 90px;

  }
  @media screen and (max-width: 800px) {
    height: 60px;

  }
`;

export const StyledHamburger = styled.div`
  position: absolute;
  left: 20px;
  top: 32px;

  // If >= L hide the hamburger
  @media screen and (min-width: 970px) {
    display: none;
  }
  @media screen and (max-width: 800px) {
    top: 13px;
  }
`;
