import Image from 'common/components/Image';
import { IchiTheme } from 'common/models/theme';
import React from 'react';
import { ThemeContext } from 'styled-components';
import { StyledIchiLogo } from './ichiLogoMobile.style';

const IchiLogoMobile: React.FC = () => {
  const theme = React.useContext<IchiTheme>(ThemeContext);
  return (
    <StyledIchiLogo>
      <Image className="icon-ichi-mobile" alt="ICHI logo" />
    </StyledIchiLogo>
  );
};

export default IchiLogoMobile;
