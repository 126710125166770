import { useLocation, WindowLocation } from '@reach/router';
import cx from 'classnames';
import IchiLogo from 'common/components/IchiLogo/IchiLogo';
import Image from 'common/components/Image';
import { SidebarItem } from 'common/models/sidebar';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import React from 'react';
import { SidebarWrapper, StyledClose, StyledFixedSidebar, StyledSidebarContents, StyledTopItem, StyledTopItems } from './navbar.style';
import Button from 'common/components/Button';

type Props = {
  items: SidebarItem[];
  isDisplayed: boolean;
  toggleSidebar: Function;
};

const getIcon = (location: WindowLocation<unknown>, item: SidebarItem): string => {
  return location.pathname === item.path ? item.icon.light.selected.publicURL : item.icon.light.default.publicURL;
};

const isActive = (location: WindowLocation<unknown>, item: SidebarItem): boolean => {
  // Gatsby can sometimes do /farm and /farm/ so account for both
  return (location.pathname === item.path) || (location.pathname.replace(/\/$/, '') === item.path);
};

const Navbar: React.FC<Props> = (props) => {
  const location = useLocation();
  const topItems = props.items?.filter((item) => item.position === 'top') || [];

  if (!props.isDisplayed) {
    return null;
  }

  const handleLogout = () => {
    if (typeof window !== 'undefined') {
      sessionStorage.removeItem('isAuthorized'); // Clear the authorization status
      window.location.reload(); // Reload the page to trigger the login check
    }
  };


  // NOTE: Do not base the css display on isDisplayed as it will break SSR
  return (
    <SidebarWrapper>
      <StyledFixedSidebar isDisplayed={props.isDisplayed} className="styled-fixed-sidebar">
        <IchiLogo />
        <StyledClose onClick={() => props.toggleSidebar()}>
          <Image
            className="icon-close"
            alt="Close sidebar"
          />
        </StyledClose>
        <StyledSidebarContents>
          <StyledTopItems>
            {topItems.map((item) => (
              <StyledTopItem key={item.path} className="styled-top-item" active={false}>
                {/* <Image
                  src={getIcon(location, item)}
                  alt={`${item.label} icon`}
                /> */}
                <Link to={item.path} className={cx({ active: isActive(location, item) })}>
                  {item.label}
                </Link>
              </StyledTopItem>
            ))}
          </StyledTopItems>
          <Button onClick={handleLogout} title='Logout' style={{background: 'none', color: '#888888'}}></Button>
        </StyledSidebarContents>
      </StyledFixedSidebar>
    </SidebarWrapper>
  );
};

export default Navbar;
