import React from 'react';
import { StyledPrefbar } from './prefbar.style';

export type PrefbarProps = {};

const Prefbar: React.FC<PrefbarProps> = (props) => {
  return (
    <StyledPrefbar className="topbar-bg-color">{props.children}</StyledPrefbar>
  );
};

export default Prefbar;
