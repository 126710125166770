import { createBreakpoint } from 'react-use';

export namespace ScreenUtils {
  export enum BreakpointName {
    XL = 'XL',
    L = 'L',
    M = 'M',
    S = 'S'
  }

  export type BreakpointNames = keyof typeof BreakpointName;

  export const breakpointMapping = {
    [BreakpointName.S]: 600,
    [BreakpointName.M]: 800,
    [BreakpointName.L]: 970,
    [BreakpointName.XL]: 1300
  };

  export const useBreakpoint = createBreakpoint({
    [BreakpointName.XL]: 1130,
    [BreakpointName.L]: 970,
    [BreakpointName.M]: 800,
    [BreakpointName.S]: 600
  }) as () => BreakpointName;
}
