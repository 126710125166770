import React from 'react';
import Spinner from './Spinner';

type Props = {
  isLoading: boolean;
  width?: number;
  height?: number;
};

const SpinnerContainer: React.FC<Props> = (props) => {
  if (props.isLoading) {
    return (
      <Spinner
        width={props.width || 25}
        height={props.height || 25}
        className="text-spinner"
        isLoading={props.isLoading}
      />
    );
  }
  return (
    <>
      {props.children}
    </>
  );
};

export default SpinnerContainer;
