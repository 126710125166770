import { darkTheme } from 'common/theme/dark/darkTheme';
import { lightTheme } from 'common/theme/light/lightTheme';
import styled, { createGlobalStyle } from 'styled-components';
import IconOne1Inch from '../common/assets/image/ichi/oneTokens/logo_256_1inch.svg';
import IconOneBtc from '../common/assets/image/ichi/oneTokens/logo_256_bitcoin.svg';
import IconOneLink from '../common/assets/image/ichi/oneTokens/logo_256_chainlink.svg';
import IconOneEth from '../common/assets/image/ichi/oneTokens/logo_256_eth.svg';
import IconOneFil from '../common/assets/image/ichi/oneTokens/logo_256_filecoin.svg';
import IconOneFUSE from '../common/assets/image/ichi/oneTokens/logo_256_oneFUSE.svg';
import IconOneFOX from '../common/assets/image/ichi/oneTokens/logo_256_oneFOX.svg';
import IconOneMPH from '../common/assets/image/ichi/oneTokens/onemph-logo.svg';
import IconOnePERL from '../common/assets/image/ichi/oneTokens/logo_256_onePERL.svg';
import IconOneDODO from '../common/assets/image/ichi/oneTokens/logo_256_oneDODO.svg';
import IconOneTokens from '../common/assets/image/ichi/oneTokens/logo_256_oneTokens.svg';
import IconOneUni from '../common/assets/image/ichi/oneTokens/logo_256_oneUNI.svg';
import IconUni from '../common/assets/image/ichi/oneTokens/logo_256_uni.svg';
import IconOneVBTC from '../common/assets/image/ichi/oneTokens/logo_256_vBTC.svg';
import IconOneWing from '../common/assets/image/ichi/oneTokens/logo_256_wing.svg';
import IconQuantstamp from '../common/assets/image/ichi/quantstamp.png';
import Icon1Inch from '../common/assets/image/ichi/tokens/1inch.svg';
import Icon1InchTest from '../common/assets/image/ichi/tokens/1inch_test.svg';
import IconBnt from '../common/assets/image/ichi/tokens/bnt.svg';
import IconBntTest from '../common/assets/image/ichi/tokens/bnt_test.svg';
import IconLink from '../common/assets/image/ichi/tokens/chainlink.svg';
import IconFilTest from '../common/assets/image/ichi/tokens/fil_test.svg';
import IconGeneralTest from '../common/assets/image/ichi/tokens/general_test.png';
import IconUniTest from '../common/assets/image/ichi/tokens/uni_test.svg';
import IconUsdc from '../common/assets/image/ichi/tokens/usdc.svg';
import IconUsdcTest from '../common/assets/image/ichi/tokens/usdc_test.svg';
import IconVBTC from '../common/assets/image/ichi/tokens/vBTC.png';
import IconWingTest from '../common/assets/image/ichi/tokens/wing_test.svg';
// https://icons8.com/icons/set/white-plus
import IconPlusGray from '../common/assets/image/ichi/icons/dark/icon_plus_gray.svg';
import IconPlusBlack from '../common/assets/image/ichi/icons/light/icon_plus_black.svg';
import ArrowLeftGreen from '../common/assets/image/ichifarm/arrow-left-green.svg';

// if (process.env.NODE_ENV === 'development') {
//   // eslint-disable-next-line global-require
//   const { inspect } = require('@xstate/inspect');
//   inspect({
//     // options
//     // url: 'https://statecharts.io/inspect', // (default)
//     iframe: false // open in new window
//   });
// }

export const GlobalStyle = createGlobalStyle`
  html {
    min-width: 375px; // iPhone X width

    /* With Typography this is critical for the site to look correct otherwise there is a double scroll bar */
    /* and the sticky get messed up */
    /* https://stackoverflow.com/questions/13028584/browsers-scrollbar-is-under-my-fixed-div */
    /* https://www.gatsbyjs.com/plugins/gatsby-plugin-typography/ */
    overflow:auto;
    overflow:initial;
  }
  body {
    /* font-family: 'Roboto', sans-serif; */
    font-family: Montserrat;

    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

    box-sizing: border-box;
    /* display: flex;
    justify-content: center; */
    min-width: 375px; // iPhone X width
  }

  img {
    // Typography appears to add a margin-bottom: 1.666rem to all img elements
    // no idea why
    margin-bottom: unset;
  }

  & .w-52{
    width: 208px;
  }

  & .m-05 {
    margin: 2px;
  }
  & .m-1 {
    margin: 4px;
  }
  & .m-10 {
    margin: 10px;
  }

  /* Margin Left */
  & .ml-0 {
    margin-left: 0px;
  }
  & .ml-5 {
    margin-left: 5px;
  }
  & .ml-10 {
    margin-left: 10px;
  }
  & .ml-20 {
    margin-left: 20px;
  }
  & .ml-40 {
    margin-left: 40px;
  }
  /* Margin Right */
  & .mr-5 {
    margin-right: 5px;
  }
  & .mr-10 {
    margin-right: 10px;
  }
  & .mr-20 {
    margin-right: 20px;
  }
  /* Margin Top */
  & .mt-6 {
    margin-top: 6px;
  }
  & .mt-10 {
    margin-top: 10px;
  }
  & .mt-20 {
    margin-top: 20px;
  }
  & .mt-30 {
    margin-top: 30px;
  }
  & .mt-45 {
    margin-top: 45px;
  }
  /* Margin Bottom */
  & .mb-20 {
    margin-bottom: 20px;
  }
  /* Padding Top */
  & .pt-3 {
    padding-top: 3px;
  }
  & .pb-3 {
    padding-bottom: 3px;
  }
  & .pb-10 {
    padding-bottom: 10px;
  }
  & .pb-20 {
    padding-bottom: 20px;
  }
  & .pb-40 {
    padding-bottom: 40px;
  }
  & .px-1{
    padding-left: 4px;
    padding-right: 4px;
  }
  & .px-3{
    padding-left: 12px;
    padding-right: 12px;
  }
  & .py-1{
    padding-top: 4px;
    padding-bottom: 4px;
  }
  & .p-2{
    padding: 8px;
  }
  & .p-10{
    padding: 40px;
  }
  & .h-370 {
    height: 370px;
  }
  & .h-275 {
    height: 275px;
  }
  & .h-175 {
    height: 175px;
  }
  & .rounded-2{
    border-radius: 8px;
  }
  & .no-border-bottom {
    border-bottom: none;
  }
  & .cursor-pointer{
    cursor: pointer;
  }
  & .underline{
    text-decoration: underline;
  }

  & .white-color {
    color: #ffffff;
  }
  & .cursor-pointer {
    cursor: pointer;
  }
  & .text-small {
    font-size: 13px;
  }

  // See FormatNumber, this basically avoids theming and assumes the current colors
  & .loadable-small {
    background-color: inherit;
    border: none;

    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.02px;
    line-height: 19px;

    @keyframes load {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    & div.loader {
      border: 2px solid rgba(255, 255, 255, 0.2);
      border-left: 2px solid;
      animation: load 1s infinite linear;
      border-radius: 50%;
      width: 15px;
      height: 15px;
    }


    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  }

  & .loadable-default {

    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.02px;
    line-height: 19px;

    @keyframes load {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    & div.loader {
      border: 4px solid rgba(255, 255, 255, 0.2);
      border-left: 4px solid;
      animation: load 1s infinite linear;
      border-radius: 50%;
      width: 25px;
      height: 25px;
    }


    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  }

  /* ------------------------------------- */
  /* Non-themed icons  */
  /* ------------------------------------- */
  & .icon-oneeth {
    content: url(${IconOneEth});
  }
  & .icon-oneETH {
    content: url(${IconOneEth});
  }
  & .icon-onebtc {
    content: url(${IconOneBtc});
  }
  & .icon-oneBTC {
    content: url(${IconOneBtc});
  }
  & .icon-1inch {
    content: url(${Icon1Inch});
  }
  & .icon-link {
    content: url(${IconLink});
  }
  & .icon-onelink {
    content: url(${IconOneLink});
  }
  & .icon-oneLINK {
    content: url(${IconOneLink});
  }
  & .icon-onewing {
    content: url(${IconOneWing});
  }
  & .icon-oneWING {
    content: url(${IconOneWing});
  }
  & .icon-onevbtc {
    content: url(${IconOneVBTC});
  }
  & .icon-oneVBTC {
    content: url(${IconOneVBTC});
  }
  & .icon-usdc {
    content: url(${IconUsdc});
  }
  & .icon-USDC {
    content: url(${IconUsdc});
  }
  & .icon-vbtc {
    content: url(${IconVBTC});
  }
  & .icon-bnt {
    content: url(${IconBnt});
  }
  & .icon-bpt {
    content: url(${IconOneTokens});
  }
  & .icon-uni {
    content: url(${IconUni});
  }
  & .icon-oneuni {
    content: url(${IconOneUni});
  }
  & .icon-oneUNI {
    content: url(${IconOneUni});
  }
  & .icon-onefil {
    content: url(${IconOneFil});
  }
  & .icon-oneFIL {
    content: url(${IconOneFil});
  }
  & .icon-one1inch {
    content: url(${IconOne1Inch});
  }
  & .icon-one1INCH {
    content: url(${IconOne1Inch});
  }
  & .icon-onefuse {
    content: url(${IconOneFUSE});
  }
  & .icon-onemph {
    content: url(${IconOneMPH});
  }
  & .icon-oneperl {
    content: url(${IconOnePERL});
  }
  & .icon-onedodo {
    content: url(${IconOneDODO});
  }
  & .icon-onefox {
    content: url(${IconOneFOX});
  }
  & .arrow-left-green {
    content: url(${ArrowLeftGreen});
  }
  & .icon-quantstamp {
    content: url(${IconQuantstamp});
    padding-top: 120px;
  }

  // --------------------------------
  // NOTE: For Kovan testing
  // --------------------------------
  & .icon-OTI {
    content: url(${IconGeneralTest});
  }
  & .icon-oti {
    content: url(${IconGeneralTest});
  }
  & .icon-test_onefil {
    content: url(${IconFilTest});
  }
  & .icon-test_oneFIL {
    content: url(${IconFilTest});
  }
  & .icon-test_oneuni {
    content: url(${IconUniTest});
  }
  & .icon-test_oneUNI {
    content: url(${IconUniTest});
  }
  & .icon-weenus {
    content: url(${IconGeneralTest});
  }
  & .icon-Token6 {
    content: url(${IconGeneralTest});
  }
  & .icon-test_1inch {
    content: url(${Icon1InchTest});
  }
  & .icon-test_bnt {
    content: url(${IconBntTest});
  }
  & .icon-test_fil {
    content: url(${IconFilTest});
  }
  & .icon-test_uni {
    content: url(${IconUniTest});
  }
  & .icon-test_usdc {
    content: url(${IconUsdcTest});
  }
  & .icon-test_USDC {
    content: url(${IconUsdcTest});
  }
  & .icon-test_wing {
    content: url(${IconWingTest});
  }

  & .styled-claim-rewards-button {
    margin-top: 10px;
    cursor: pointer;
    border: none;
    border-radius: 15px;
    padding: 7px 20px;
    min-width: 120px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.01px;
    line-height: 15px;
  }

  &.dark-mode {

    background-color: ${darkTheme.colors.backgroundColor};
    color: white;
    /* transition: background-color .5s ease; */

    input {
      caret-color: ${darkTheme.colors.inputPlaceholderColor};
      color: ${darkTheme.colors.inputPlaceholderColor};
    }

    /* ------------------------------------- */
    /* Wallet  */
    /* ------------------------------------- */
    .wallet-button-bg-color {
      background-color: ${darkTheme.colors.walletButtonBgColor};
    }
    .wallet-etherscan-color {
      color: ${darkTheme.colors.sidebarItemSelectedColor};
    }
    .wallet-container {
      background-color: ${darkTheme.colors.contentInnerBgColor};
      border: 2px solid transparent;
      &.active {
        border: 2px solid #026DEF;
      }
    }
    .wallet-popper-inner-bg-color {
      background-color: ${darkTheme.colors.transparent};
    }
    .wallet-name {
      color: ${darkTheme.colors.subTextColor};
    }
    .wallet-eth {
      border: 2px solid ${darkTheme.colors.backgroundColor};
      background: linear-gradient(315deg, #60658B 0%, #878DAC 100%);
    }

    /* ------------------------------------- */
    /* General  */
    /* ------------------------------------- */
    & .styled-hamburger {
      rect {
        stroke: ${darkTheme.colors.hamburgerIcon};
        fill: ${darkTheme.colors.hamburgerIcon};
      }
    }

    & .styled-fixed-sidebar {
      background-color: ${darkTheme.colors.sidebarBackgroundColor};
      /* transition: background-color .5s ease; */
    }

    & .styled-topbar {
      background-color: ${darkTheme.colors.sidebarBackgroundColor};
      /* transition: background-color .5s ease; */
    }

    & .styled-content {
      background-color: ${darkTheme.colors.contentBgColor};

      // When the sidebar disappears then change color
      @media screen and (max-width: 970px) {
        background-color: ${darkTheme.colors.backgroundColor};
      }
      @media (max-width: 970px) {
        width: 100%;
        border-radius: 0px !important;
      }
      /* transition: background-color .5s ease; */
    }

    & .content-inner-bg-color {
      background-color: ${darkTheme.colors.contentInnerBgColor};
      /* transition: background-color .5s ease; */
    }

    & .content-inner-inner-bg-color {
      background-color: ${darkTheme.colors.contentInnerInnerBgColor};
      /* transition: background-color .5s ease; */
    }

    & .announcement-bg-color {
      background-color: ${darkTheme.colors.annoncementBgColor};
      /* transition: background-color .5s ease; */
    }

    & .primary-bg-color {
      background-color: ${darkTheme.colors.primary};
      /* transition: background-color .5s ease; */
    }

    & .primary-color {
      color: ${darkTheme.colors.primary};
      /* transition: color .5s ease; */
    }

    & .primary-text-color {
      color: ${darkTheme.colors.primaryTextColor};
      /* transition: color .5s ease; */
    }

    & .warning-text-color {
      color: ${darkTheme.colors.warningTextColor};
      /* transition: color .5s ease; */
    }

    & .alert-text-color {
      color: red;
      /* transition: color .5s ease; */
    }

    & .alert-state {
      background-color: #c00000;
      padding: 2px 5px;
      border-radius: 9px;
      color: #aaffaa;
      margin: -5px;
    }

    & .sub-text-color {
      color: ${darkTheme.colors.subTextColor};
      /* transition: color .5s ease; */
    }

    & .heading-color {
      color: ${darkTheme.colors.headingColor};
      /* transition: color .5s ease; */
    }

    & .footer-bg-color {
      background-color: ${darkTheme.colors.footerBgColor};
      /* transition: background-color .5s ease; */
    }

    & .primary-button-bg-color {
      background-color: ${darkTheme.colors.primary};
      /* transition: background-color .5s ease; */
    }
    & .primary-button-disabled-bg-color {
      background-color: ${darkTheme.colors.primaryButtonDisabledBgColor};
      // cursor: not-allowed;
      /* transition: background-color .5s ease; */
    }
    & .primary-button-color {
      color: ${darkTheme.colors.white};
      /* transition: color .5s ease; */
    }

    & .secondary-button-bg-color {
      background-color: ${darkTheme.colors.primary};
      border: 1px solid ${darkTheme.colors.primary};
    }
    & .secondary-button-bg-color:hover {
      border: 1px solid ${darkTheme.colors.white};
    }
    & .secondary-button-color {
      color: ${darkTheme.colors.white};
      /* transition: color .5s ease; */
    }

    & .inner-box-bg-color {
      background-color: ${darkTheme.colors.modalBgColor};
      /* transition: background-color .5s ease; */
    }
    & .inner-box-shadow {
      background-color: ${darkTheme.colors.modalBgColor};
      box-shadow: none;
      /* transition: background-color .5s ease; */
    }

    & .hr-bg-color {
      background-color: ${darkTheme.colors.hrBgColor};
      /* transition: background-color .5s ease; */
    }

    & .text-spinner {
      & div.loader {
        border: 4px solid rgba(255, 255, 255, 0.2);
        border-left: 4px solid;
        animation: load 1s infinite linear;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        color: ${darkTheme.colors.spinnerColor};
      }
    }

    & .topbar-bg-color {
      background-color: ${darkTheme.colors.backgroundColor};
      /* transition: background-color .5s ease; */
    }

    /* ------------------------------------- */
    /* Tooltips  */
    /* ------------------------------------- */
    & .tooltip-top {
      bottom: 100%;
      padding-bottom: 9px;

      &::after {
        border-top: 9px solid ${darkTheme.colors.modalBgColor};
        bottom: 0;
      }
    }

    & .tooltip-bottom {
      top: 100%;
      padding-top: 9px;

      &::after {
        border-bottom: 9px solid ${darkTheme.colors.modalBgColor};
        top: 0;
      }
    }

    & .tooltip-left {
      right: 100%;
      padding-right: 9px;

      &::after {
        border-left: 9px solid ${darkTheme.colors.modalBgColor};
        right: 0;
      }
    }

    & .tooltip-right {
      left: 100%;
      padding-left: 9px;

      &::after {
        border-right: 9px solid ${darkTheme.colors.modalBgColor};
        left: 0;
      }
    }

    & .tooltip-bubble {
      color: ${darkTheme.colors.white};
      background-color: ${darkTheme.colors.contentBgColor};
    }


    /* ------------------------------------- */
    /* tabs  */
    /* ------------------------------------- */
    & .tabs-container {
      & .rc-tabs-nav-wrap {
        border-bottom: 1px solid ${darkTheme.colors.modalBgColor};
        &  .rc-tabs-tab {
          color: ${darkTheme.colors.inactiveTabColor};
          &.rc-tabs-tab-active {
            color: ${darkTheme.colors.activeTabColor};
          }
        }
        & .rc-tabs-ink-bar {
          background: ${darkTheme.colors.sidebarItemSelectedColor};
        }
      }

      // This hides the 'More' button on Firefox
      & .rc-tabs-nav-operations {
        & .rc-tabs-nav-more {
          display: none;
        }
      }
    }
    & .btn-view-all {
      color: ${darkTheme.colors.btnViewAllColor};
    }

    /* ------------------------------------- */
    /* Table  */
    /* ------------------------------------- */
    & .table-border-bottom {
      border-bottom: ${darkTheme.colors.tableBorderBottom};
      /* transition: border-bottom .5s ease; */
    }
    & .table-no-border-bottom {
      border-bottom: ${darkTheme.colors.backgroundColor};
      /* transition: border-bottom .5s ease; */
    }
    & .table-no-border-bottom:nth-of-type(2n) td {
      background-color: rgba(127, 127, 127, .1);
      /* transition: border-bottom .5s ease; */
    }
    & .treasury-table-sub-text-two-color {
      color: ${darkTheme.colors.treasuryTableSubTextTwoColor};
      /* transition: color .5s ease; */
    }

    /* ------------------------------------- */
    /* Tooltips  */
    /* ------------------------------------- */
    & .tooltip-message {
      background-color: ${darkTheme.colors.modalBgColor};
      border: ${darkTheme.colors.inputBorder};
      color: white;
      /* This looks terrible in dark theme, commenting for now */
      /*box-shadow: ${darkTheme.colors.primaryBoxShadow};*/
    }

    /* ------------------------------------- */
    /* ICHI Balance  */
    /* ------------------------------------- */
    & .ichi-balance-bg-color {
      background-color: ${darkTheme.colors.ichiBalanceBgColor};
      /* transition: background-color .5s ease; */
    }
    & .ichi-balance-header-border-bottom {
      border-bottom: ${darkTheme.colors.ichiBalanceHeaderBorderBottom};
      /* transition: border-bottom .5s ease; */
    }
    & .ichi-balance-header-text-color {
      color: ${darkTheme.colors.ichiBalanceHeaderTextColor};
      /* transition: color .5s ease; */
    }
    & .ichi-balance-button-bg-color {
      background-color: ${darkTheme.colors.ichiBalanceButtonBgColor};
      background-image: ${darkTheme.colors.ichiBalanceButtonBgImage};
      /* transition: background-color .5s ease; */
    }

    /* ------------------------------------- */
    /* search  */
    /* ------------------------------------- */
    & .input-border {
      border: ${darkTheme.colors.inputBorder};
      /* transition: border .5s ease; */
    }
    & .input-bg-color {
      background-color: ${darkTheme.colors.inputBgColor};
      /* transition: background-color .5s ease; */
    }
    & .input-label-color {
      color: ${darkTheme.colors.inputLabelColor};
      /* transition: color .5s ease; */
    }
    & .input-placeholder-color {

      &::placeholder {
        color: ${darkTheme.colors.inputPlaceholderColor};
        /* transition: color .5s ease; */
      }
    }

    /* ------------------------------------- */
    /* Bottombar  */
    /* ------------------------------------- */
    & .styled-bottombar {
      background-color: ${darkTheme.colors.backgroundColor};
    }

    /* ------------------------------------- */
    /* Sidebar  */
    /* ------------------------------------- */
    & .styled-top-item {
      & a {
        color: ${darkTheme.colors.sidebarTextColor};
        font-weight: 500;
        /* transition: color .5s ease; */
        &.active {
          color: ${darkTheme.colors.sidebarTextSelectedColor};
          border-bottom: 2px solid ${darkTheme.colors.sidebarTextSelectedColor};
          /* transition: color .5s ease; */
        }
      }
    }

    & .styled-bottom-items {
      border-top: 1px solid ${darkTheme.colors.borderColor};
      & a {
        color: ${darkTheme.colors.sidebarTextColor};
        /* transition: color .5s ease; */
      }
    }

    /* ------------------------------------- */
    /* Farm  */
    /* ------------------------------------- */
    & .styled-go-back {
      & a {
        color: ${darkTheme.colors.primary};
        /* transition: color .5s ease; */
      }
    }
    & .styled-farm-header {
      background-color: ${darkTheme.colors.contentBgColor};
      /* transition: background-color .5s ease; */
    }
    & .styled-farm-sub-header-text {
      color: ${darkTheme.colors.headingColor};
      /* transition: color .5s ease; */
    }
    & .styled-no-rewards-button {
      background-color: ${darkTheme.colors.noRewardsButtonColor};
      /* transition: background-color .5s ease; */
    }
    & .styled-claim-rewards-button-color {
      background-color: ${darkTheme.colors.primaryButtonBgColor};
      /* transition: background-color .5s ease; */
    }

    /* ------------------------------------- */
    /* Treasury  */
    /* ------------------------------------- */
    & .treasury-position-box {
      border: ${darkTheme.colors.treasuryPositionBorder};
      background-color: ${darkTheme.colors.treasuryPositionBgColor};
    }
    & .treasury-position-name-color {
      color: ${darkTheme.colors.treasuryPositionNameColor};
      /* transition: color .5s ease; */
    }

    /* ------------------------------------- */
    /* Icons  */
    /* ------------------------------------- */
    & .icon-plus {
      content: url(${IconPlusGray});
    }
    & .icon-theme-switcher {
      content: url(${darkTheme.icons.theme});
      @media (max-width: 970px) {
        display: none;
      }
    }
    & .icon-theme-switcher-mobile {
      content: url(${darkTheme.icons.themeMobile});
      width: 60px;
      height: 60px;
      @media (min-width: 969px) {
        display: none;
      }
    }
    & .icon-preferences-active {
      content: url(${darkTheme.icons.preferences.active});
    }
    & .icon-preferences {
      content: url(${darkTheme.icons.preferences.inactive});
      &.active {
        content: url(${darkTheme.icons.preferences.active});
      }
      @media (max-width: 800px) {
        display: none;
      }
    }
    & .icon-preferences-mobile {
      content: url(${darkTheme.icons.preferences.inactiveMobile});
      width: 80px;
      height: 80px;
      @media (min-width: 799px) {
        display: none;
      }
    }
    & .icon-new-window {
      content: url(${darkTheme.icons.newWindow});
      margin-bottom: 2px;
    }
    & .icon-close {
      content: url(${darkTheme.icons.close});
    }
    & .icon-ichi-owned {
      content: url(${darkTheme.icons.ichiOwned});
    }
    & .icon-ichi {
      content: url(${darkTheme.icons.ichiOwned});
    }
    & .icon-ichi-mobile {
      content: url(${darkTheme.icons.ichiWhite});
      height: 34px;
      width: 34px;
    }
    & .icon-lp {
      content: url(${darkTheme.icons.lp});
    }
    & .icon-eth {
      content: url(${darkTheme.icons.eth});
    }
    & .icon-eth-wallet {
      content: url(${darkTheme.icons.ethWhite});
    }
    & .icon-Token18 {
      content: url(${darkTheme.icons.eth});
    }
    & .icon-WETH {
      content: url(${darkTheme.icons.eth});
    }
    & .icon-weth {
      content: url(${darkTheme.icons.eth});
    }
    & .icon-wbtc {
      content: url(${darkTheme.icons.wbtc});
    }
    & .icon-renFIL {
      content: url(${darkTheme.icons.renFIL});
    }
    & .icon-test-renfil {
      content: url(${darkTheme.icons.renFIL});
    }
    & .icon-help {
      content: url(${darkTheme.icons.help});
    }
    // --------------------------------
    // NOTE: For Kovan testing
    // --------------------------------
    & .icon-test_ichi {
      content: url(${darkTheme.icons.ichiOwned});
    }
    & .icon-test_weth {
      content: url(${darkTheme.icons.eth});
    }

    /* ------------------------------------- */
    /* Wallet Popper  */
    /* ------------------------------------- */
    & .wallet-popper-header-border-bottom {
      border-bottom: ${darkTheme.colors.walletPopperHeaderBorderBottom};
      /* transition: border-bottom .5s ease; */
    }
    & .wallet-popper-text-color {
      background-color: ${darkTheme.colors.walletPopperTextColor};
      /* transition: background-color .5s ease; */
    }

    /* ------------------------------------- */
    /* Select Token */
    /* ------------------------------------- */
    & .styled-select-token {
      border: none;
      & select {
        background-color: ${darkTheme.colors.contentInnerBgColor};
        /* transition: background-color .5s ease; */
      }

      & .select__control {
        background-color: ${darkTheme.colors.contentInnerBgColor};
        /* transition: background-color .5s ease; */

        &:hover {
          border: none;
          box-shadow: none;
        }
      }

      & .select__menu {

        // This seems to be the outside border/container of the actual dropdown
        & .select__menu-list {
          background-color: ${darkTheme.colors.contentInnerBgColor};
        }

        // This is each item in the actual dropdown
        & .select__option {
          background-color: ${darkTheme.colors.contentInnerBgColor};

          &:hover {
            background-color: ${darkTheme.colors.contentBgColor};
          }
        }
      }
    }
  }

  &.light-mode {
    background-color: ${lightTheme.colors.backgroundColor};
    /* transition: background-color .5s ease; */

    input {
      caret-color: ${lightTheme.colors.inputPlaceholderColor};
      color: ${lightTheme.colors.inputPlaceholderColor};;
    }

    /* ------------------------------------- */
    /* Wallet  */
    /* ------------------------------------- */
    .wallet-button-bg-color {
      background-color: ${lightTheme.colors.walletButtonBgColor};
    }
    .wallet-etherscan-color {
      color: ${lightTheme.colors.headingColor};
    }
    .wallet-container {
      background-color: ${lightTheme.colors.white};
      border: 2px solid transparent;
      &.active {
        border: 2px solid ${lightTheme.colors.primary};
      }
    }
    .wallet-popper-inner-bg-color {
      background-color: ${lightTheme.colors.contentInnerInnerBgColor};
    }
    .wallet-name {
      color: ${lightTheme.colors.headingColor};
    }
    .wallet-eth {
      border: 2px solid ${lightTheme.colors.backgroundColor};
      background: linear-gradient(315deg, #60658B 0%, #878DAC 100%);
    }

    /* ------------------------------------- */
    /* General  */
    /* ------------------------------------- */
    & .styled-hamburger {
      rect {
        stroke: ${lightTheme.colors.hamburgerIcon};
        fill: ${lightTheme.colors.hamburgerIcon};
      }
    }

    & .styled-fixed-sidebar {
      background-color: ${lightTheme.colors.sidebarBackgroundColor};
      /* transition: background-color .5s ease; */
    }

    & .styled-content {
      background-color: ${lightTheme.colors.contentBgColor};
      box-shadow: 0px 0px 20px 2px rgb(0 0 0 / 8%);
      @media (max-width: 970px) {
        box-shadow: none;
      }
      /* transition: background-color .5s ease; */
    }

    & .styled-topbar {
      background-color: ${lightTheme.colors.sidebarBackgroundColor};
      /* transition: background-color .5s ease; */
      // box-shadow: 0px 0px 18px 2px #bbbbbb;
    
    }

    & .content-inner-bg-color {
      background-color: ${lightTheme.colors.contentInnerBgColor};
      /* transition: background-color .5s ease; */
    }

    & .content-inner-inner-bg-color {
      background-color: ${lightTheme.colors.contentInnerInnerBgColor};
      /* transition: background-color .5s ease; */
    }

    & .announcement-bg-color {
      background-color: ${lightTheme.colors.annoncementBgColor};
      /* transition: background-color .5s ease; */
    }

    & .primary-bg-color {
      background-color: ${lightTheme.colors.primary};
      /* transition: background-color .5s ease; */
    }

    & .primary-color {
      color: ${lightTheme.colors.primary};
      /* transition: color .5s ease; */
    }

    & .primary-text-color {
      color: ${lightTheme.colors.primaryTextColor};
      /* transition: color .5s ease; */
    }

    & .warning-text-color {
      color: ${lightTheme.colors.warningTextColor};
      /* transition: color .5s ease; */
    }

    & .alert-text-color {
      color: red;
      /* transition: color .5s ease; */
    }

    & .alert-state {
      background-color: #c00000;
      padding: 2px 5px;
      border-radius: 9px;
      color: #aaffaa;
      margin: -5px;
    }

    & .sub-text-color {
      color: ${lightTheme.colors.subTextColor};
      /* transition: color .5s ease; */
    }

    & .heading-color {
      color: ${lightTheme.colors.headingColor};
      /* transition: color .5s ease; */
    }

    & .footer-bg-color {
      background-color: ${lightTheme.colors.footerBgColor};
      /* transition: background-color .5s ease; */
    }

    & .primary-button-bg-color {
      background-color: ${lightTheme.colors.primary};
      /* transition: background-color .5s ease; */
    }
    & .primary-button-disabled-bg-color {
      background-color: ${lightTheme.colors.primaryButtonDisabledBgColor};
      // cursor: not-allowed;
      /* transition: background-color .5s ease; */
    }
    & .primary-button-color {
      color: ${lightTheme.colors.white};
      /* transition: color .5s ease; */
    }
    & .secondary-button-bg-color {
      background-color: ${lightTheme.colors.secondaryButtonBgColor};
      border: 1px solid ${lightTheme.colors.secondaryButtonBgColor};
      /* transition: background-color .5s ease; #e8f8f3 */
    }
    & .secondary-button-bg-color:hover {
      border: 1px solid ${lightTheme.colors.primary};
      /* transition: background-color .5s ease; #e8f8f3 */
    }
    & .secondary-button-color {
      color: ${lightTheme.colors.primary};
      /* transition: color .5s ease; */
    }
    & .inner-box-bg-color {
      background-color: ${lightTheme.colors.modalBgColor};
      /* transition: background-color .5s ease; */
    }
    & .inner-box-shadow {
      background-color: ${lightTheme.colors.modalBgColor};
      box-shadow: 0 2px 40px 0 rgba(0, 56, 124, 0.15);
      /* transition: background-color .5s ease; */
    }

    & .hr-bg-color {
      background-color: ${lightTheme.colors.hrBgColor};
      /* transition: background-color .5s ease; */
    }

    & .text-spinner {
      & div.loader {
        border: 4px solid rgba(255, 255, 255, 0.2);
        border-left: 4px solid;
        animation: load 1s infinite linear;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        color: ${lightTheme.colors.spinnerColor};
      }
    }

    & .topbar-bg-color {
      background-color: ${lightTheme.colors.backgroundColor};
      /* transition: background-color .5s ease; */
    }


    /* ------------------------------------- */
    /* Tooltips  */
    /* ------------------------------------- */
    & .tooltip-top {
      bottom: 100%;
      padding-bottom: 9px;

      &::after {
        border-top: 9px solid ${lightTheme.colors.contentBgColor};
        bottom: 0;
      }
    }

    & .tooltip-bottom {
      top: 100%;
      padding-top: 9px;

      &::after {
        border-bottom: 9px solid ${lightTheme.colors.contentBgColor};
        top: 0;
      }
    }

    & .tooltip-left {
      right: 100%;
      padding-right: 9px;

      &::after {
        border-left: 9px solid ${lightTheme.colors.contentBgColor};
        right: 0;
      }
    }

    & .tooltip-right {
      left: 100%;
      padding-left: 9px;

      &::after {
        border-right: 9px solid ${lightTheme.colors.contentBgColor};
        left: 0;
      }
    }

    & .tooltip-bubble {
      color: ${lightTheme.colors.white};
      background-color: ${lightTheme.colors.contentBgColor};
    }

    /* ------------------------------------- */
    /* tabs  */
    /* ------------------------------------- */
    & .tabs-container {
      & .rc-tabs-nav-wrap {
        border-bottom: 1px solid ${lightTheme.colors.modalBgColor};
        &  .rc-tabs-tab {
          color: ${lightTheme.colors.inactiveTabColor};
          &.rc-tabs-tab-active {
            color: ${lightTheme.colors.activeTabColor};
          }
        }
        & .rc-tabs-ink-bar {
          background: ${lightTheme.colors.sidebarItemSelectedColor};
        }
      }

      // This hides the 'More' button on Firefox
      & .rc-tabs-nav-operations {
        & .rc-tabs-nav-more {
          display: none;
        }
      }
    }
    & .btn-view-all {
      color: ${lightTheme.colors.btnViewAllColor};
    }

    /* ------------------------------------- */
    /* Table  */
    /* ------------------------------------- */
    & .table-border-bottom {
      border-bottom: ${lightTheme.colors.tableBorderBottom};
      /* transition: border-bottom .5s ease; */
    }
    & .table-no-border-bottom {
      border-bottom: #ffffff;
      /* transition: border-bottom .5s ease; */
    }
    & .table-no-border-bottom:nth-of-type(2n) td {
      background-color: rgba(127, 127, 127, .1);
      /* transition: border-bottom .5s ease; */
    }
    & .treasury-table-sub-text-two-color {
      color: ${lightTheme.colors.treasuryTableSubTextTwoColor};
      /* transition: color .5s ease; */
    }

    /* ------------------------------------- */
    /* Tooltips  */
    /* ------------------------------------- */
    & .tooltip-message {
      background-color: ${lightTheme.colors.modalBgColor};
      border: ${lightTheme.colors.inputBorder};
      color: ${lightTheme.colors.textColor};
      box-shadow: ${lightTheme.colors.primaryBoxShadow};
    }


    /* ------------------------------------- */
    /* ICHI Balance  */
    /* ------------------------------------- */
    & .ichi-balance-bg-color {
      background-color: ${lightTheme.colors.ichiBalanceBgColor};
      /* transition: background-color .5s ease; */
    }
    & .ichi-balance-header-border-bottom {
      border-bottom: ${lightTheme.colors.ichiBalanceHeaderBorderBottom};
      /* transition: background-color .5s ease; */
    }
    & .ichi-balance-button-bg-color {
      background-color: ${lightTheme.colors.ichiBalanceButtonBgColor};
      background-image: ${lightTheme.colors.ichiBalanceButtonBgImage};
      /* transition: background-color .5s ease; */
    }

    /* ------------------------------------- */
    /* search  */
    /* ------------------------------------- */
    & .styled-search {
    }

    & .input-border {
      border: ${lightTheme.colors.inputBorder};
      /* transition: border .5s ease; */
    }
    & .input-bg-color {
      background-color: ${lightTheme.colors.inputBgColor};
      /* transition: background-color .5s ease; */
    }
    & .input-label-color {
      color: ${lightTheme.colors.inputLabelColor};
      /* transition: color .5s ease; */
    }
    & .input-placeholder-color {
      color: ${lightTheme.colors.inputPlaceholderColor};
      /* transition: color .5s ease; */
    }

    /* ------------------------------------- */
    /* Bottombar  */
    /* ------------------------------------- */
    & .styled-bottombar {
      background-color: ${lightTheme.colors.backgroundColor};
    }

    /* ------------------------------------- */
    /* Sidebar  */
    /* ------------------------------------- */
    & .styled-top-item {
      & a {
        color: ${lightTheme.colors.sidebarTextColor};
        font-weight: 500;
        /* transition: color .5s ease; */
        &.active {
          color: ${lightTheme.colors.sidebarTextSelectedColor};
          border-bottom: 2px solid ${lightTheme.colors.sidebarTextSelectedColor};
          /* transition: color .5s ease; */
        }
      }
    }

    & .styled-bottom-items {
      border-top: 1px solid ${lightTheme.colors.borderColor};
      & a {
        color: ${lightTheme.colors.sidebarTextColor};
        /* transition: color .5s ease; */
      }
    }

    /* ------------------------------------- */
    /* Farm  */
    /* ------------------------------------- */
    & .styled-go-back {
      & a {
        color: ${lightTheme.colors.primary};
        /* transition: color .5s ease; */
      }
    }
    & .styled-farm-header {
      background-color: ${lightTheme.colors.contentBgColor};
      /* transition: background-color .5s ease; */
    }
    & .styled-farm-sub-header-text {
      color: ${lightTheme.colors.headingColor};
      /* transition: color .5s ease; */
    }
    & .styled-no-rewards-button {
      background-color: ${lightTheme.colors.noRewardsButtonColor};
      /* transition: background-color .5s ease; */
    }
    & .styled-claim-rewards-button-color {
      background-color: ${lightTheme.colors.primaryButtonBgColor};
      /* transition: background-color .5s ease; */
    }

    /* ------------------------------------- */
    /* Treasury  */
    /* ------------------------------------- */
    & .treasury-position-box {
      border: ${lightTheme.colors.treasuryPositionBorder};
      background-color: ${lightTheme.colors.treasuryPositionBgColor};
      &:hover {
        box-shadow: 0 2px 40px 0 rgba(0, 56, 124, 0.15);
        border: none;
      }
    }
    & .treasury-position-name-color {
      color: ${lightTheme.colors.treasuryPositionNameColor};
      /* transition: color .5s ease; */
    }

    /* ------------------------------------- */
    /* Icons  */
    /* ------------------------------------- */
    & .icon-plus {
      content: url(${IconPlusBlack});
    }
    & .icon-theme-switcher {
      content: url(${lightTheme.icons.theme});
      @media (max-width: 970px) {
        display: none;
      }
    }
    & .icon-theme-switcher-mobile {
      content: url(${lightTheme.icons.themeMobile});
      width: 60px;
      height: 60px;
      @media (min-width: 969px) {
        display: none;
      }
    }
    & .icon-preferences-active {
      content: url(${lightTheme.icons.preferences.active});
    }
    & .icon-preferences {
      content: url(${lightTheme.icons.preferences.inactive});
      &.active {
        content: url(${darkTheme.icons.preferences.active});
      }
      @media (max-width: 800px) {
        display: none;
      }
    }
    & .icon-preferences-mobile {
      content: url(${lightTheme.icons.preferences.inactiveMobile});
      width: 80px;
      height: 80px;
      @media (min-width: 799px) {
        display: none;
      }
    }
    & .icon-new-window {
      content: url(${lightTheme.icons.newWindow});
      margin-bottom: 2px;
    }
    & .icon-close {
      content: url(${lightTheme.icons.close});
    }
    & .icon-ichi-owned {
      content: url(${lightTheme.icons.ichiOwned});
    }
    & .icon-ichi {
      content: url(${lightTheme.icons.ichiOwned});
    }
    & .icon-ichi-mobile {
      content: url(${lightTheme.icons.ichiMobile});
      height: 34px;
      width: 34px;
    }
    & .icon-lp {
      content: url(${lightTheme.icons.lp});
    }
    & .icon-eth {
      content: url(${lightTheme.icons.eth});
    }
    & .icon-eth-wallet {
      content: url(${lightTheme.icons.ethWhite});
    }
    & .icon-Token18 {
      content: url(${lightTheme.icons.eth});
    }
    & .icon-weth {
      content: url(${lightTheme.icons.eth});
    }
    & .icon-wbtc {
      content: url(${lightTheme.icons.wbtc});
    }
    & .icon-renFIL {
      content: url(${lightTheme.icons.renFIL});
    }
    & .icon-test-renfil {
      content: url(${lightTheme.icons.renFIL});
    }
    & .icon-help {
      content: url(${lightTheme.icons.help});
    }

    // --------------------------------
    // NOTE: For Kovan testing
    // --------------------------------
    & .icon-test_ichi {
      content: url(${lightTheme.icons.ichiOwned});
    }
    & .icon-test_weth {
      content: url(${lightTheme.icons.eth});
    }

    /* ------------------------------------- */
    /* Wallet Popper  */
    /* ------------------------------------- */
    & .wallet-popper-header-border-bottom {
      border-bottom: ${lightTheme.colors.walletPopperHeaderBorderBottom};
      /* transition: background-color .5s ease; */
    }
    & .wallet-popper-text-color {
      background-color: ${lightTheme.colors.walletPopperTextColor};
      /* transition: background-color .5s ease; */
    }

    /* ------------------------------------- */
    /* Select Token */
    /* ------------------------------------- */
    & .styled-select-token {
      & select {
        background-color: ${lightTheme.colors.contentInnerBgColor};
        /* transition: background-color .5s ease; */
      }

      & .select__control {
        background-color: ${lightTheme.colors.contentInnerBgColor};
        /* transition: background-color .5s ease; */
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Roboto', sans-serif;
  }

  .farm-name{
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
  }

  .bold{
    font-weight: 600;
  }

  // Margins for easy use
  .mt-6 {
    margin-top: 6px;
  }
  .mt-10 {
    margin-top: 10px;
  }
  .mt-15 {
    margin-top: 15px;
  }
  .mt-20 {
    margin-top: 20px;
  }

  section {
    position: relative;
  }

  .drawer-content-wrapper {
    @media (max-width: 767px) {
      width: 300px!important;
    }
    .drawer-content {
      padding: 60px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media (max-width: 767px) {
        padding: 50px 40px 30px 40px;
      }
      .mobile_menu {
        margin-bottom: 40px;
        flex-grow: 1;
        @media (max-width: 767px) {
          margin-bottom: 30px;
        }
        li{
          margin-bottom: 35px;
          @media (max-width: 767px) {
            margin-bottom: 25px;
          }
          a{
            font-size: 20px;
            font-weight: 400;
            color: #343d48;
            position: relative;
            transition: 0.15s ease-in-out;
            @media (max-width: 767px) {
              font-size: 18px;
            }
            &:hover {
              color: #2aa275;
            }
            &:before{
              content: '';
              width: 7px;
              height: 7px;
              background: #2aa275;
              border-radius: 50%;
              position: absolute;
              top: 50%;
              left: -15px;
              transform: translateY(-50%);
              opacity: 0;
            }
          }
          &.is-current {
            a {
              color: #2aa275;
              &:before{
                opacity: 1;
              }
            }
          }
        }
      }
      .navbar_drawer_button button{
        width: 100%;
      }
    }

    .reusecore-drawer__close{
      width: 34px;
      height: 34px;
      position: absolute;
      top: 20px;
      right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @media (max-width: 767px) {
        top: 15px;
        right: 15px;
      }
      &:before{
        content: '\f10b';
        font-family: Flaticon;
        font-size: 26px;
        color: #2aa275;
        transform: rotate(45deg);
        display: block;
      }
    }
  }
`;

// TODO: Left off here - working with those media queries with the sidebar disappearing, gotta figure that out
// with the new width, really width needs to be % based on outter container at a certain point
export const StyledContent = styled.div`
  position: relative;
  min-height: 85vh;
  padding: 30px 44px 44px 44px;
  width: 100%;
  /* margin-right: 20px; */
  max-width: 1200px;
  min-width: 375px; // iPhone X width */
  overflow: visible !important;
  margin: 0 auto 0 auto;

  // This is roughly the spot that the min-width above would overflow the sidebar
  @media screen and (max-width: 1130px) {
    /* margin-left: 20px;
    margin-right: 20px; */
    width: 100%;
    /* margin: 0px; */
    box-shadow: none;
    width: 100%;
  }

  // Sidebar will disappear at 970px
  @media screen and (max-width: 970px) {
    /* margin-left: 20px;
    /* min-width: 1160px; */
    margin: 0px;
    box-shadow: none;
    width: 100%;
    border-radius: 0;
    padding: 20px;
    padding-top: 110px; /* height of the fixed topbar + 20px */
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    box-shadow: none;
    margin: 0px;
    padding-top: 80px; /* height of the fixed topbar + 20px */
    padding-bottom: 90px; /* bottom bar+ 20px */
  }

  @media screen and (max-width: 400px) {
    width: 100%;
    box-shadow: none;
    margin: 0px;
  }

  /* transition: background-color .5s ease; */
  margin-bottom: 50px;
  border-radius: 20px;
  z-index: 1;

  // original styling below
  overflow: hidden;
  .sticky-nav-active {
    .saas_navbar {
      background: #fff;
      box-shadow: 0px 3px 8px 0px rgba(43, 83, 135, 0.08);
      padding: 15px 0;
      .main-logo {
        display: none;
      }
      .logo-alt {
        display: block;
      }
      .main_menu {
        li {
          a {
            color: #000;
            &:hover {
              color: #2aa275;
            }
          }
          &.is-current {
            a {
              color: #2aa275;
            }
          }
        }
      }
    }
    .hamburgMenu__bar {
      > span {
        background: #2aa275;
      }
    }
  }

  .saas_navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: 0.35s ease-in-out;
    padding: 30px 0;
    .logo-alt {
      display: none;
    }
    .main_menu {
      margin-right: 40px;
      li {
        display: inline-block;
        padding-left: 13px;
        padding-right: 13px;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        &.is-current {
          a {
            color: #edcd37;
          }
        }
        a {
          padding: 5px;
          font-size: 16px;
          font-weight: 400;
          color: #fff;
          transition: 0.15s ease-in-out;
          &:hover {
            color: #edcd37;
          }
        }
      }
      @media (max-width: 990px) {
        display: none;
      }
    }
    .navbar_button {
      @media (max-width: 990px) {
        display: none;
      }
    }
    .reusecore-drawer__handler {
      @media (min-width: 991px) {
        display: none !important;
      }
      .hamburgMenu__bar {
        > span {
        }
      }
    }
  }
`;

export const ContentTableWrapper = styled.div`
  padding-top: 24px;
  overflow: auto;

  @media screen and (max-width: 1110px){
    width: 100%;
  }
  @media screen and (max-width: 970px){
    width: 100%;
    padding-top: 0px;
  }
  @media screen and (max-width: 800px){
  }
`;
