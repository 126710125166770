import styled from 'styled-components';

export const StyledThemeSwitcher = styled.div`
    margin-left: 10px;
    position: flex;
    align-items: flex-end;
`;

export const StyledThemeIcon = styled.div`
    cursor: pointer;
`;
