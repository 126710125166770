import styled from 'styled-components';

export const StyledIchiLogo = styled.div`
  position: absolute;
  // top: 13px;
  left: 50%;
  margin-left: -12px;

  @media screen and (min-width: 970px) {
    display: none;
  }
`;
